<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        If a bar of a fictional metal containing
        <number-value :value="molMetal" unit="\text{mol}" />
        of the metal at
        <number-value :value="tempMetal" unit="^\circ\text{C}" />
        is added to
        <number-value :value="volWater" unit="\text{L}" />
        of water initially at
        <stemble-latex content="$12.5\,^\circ\text{C (density}=1.00\,\text{kg/L)}$" />
        held in a Styrofoam container and allowed to come to thermal equilibrium. The heat capacity
        of the solid metal is
        <number-value :value="Cmetal" unit="\text{J}\,\text{mol}^{-1}\,^\circ\text{C}^{-1}" />
        and
        <stemble-latex content="$75.291\,\text{J}\,\text{mol}^{-1}\,^\circ\text{C}^{-1}$" />
        for liquid water.
      </p>

      <p class="mb-2">a) How many moles of water are in the container?</p>

      <calculation-input
        v-model="inputs.molWater"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">
        b) Why is it important that we were told that the system came to thermal equilibrium in a
        Styrofoam container? Choose the best answer.
      </p>

      <v-radio-group
        v-model="inputs.significanceStyrofoam"
        :disabled="!allowEditing"
        class="ml-6 mb-0"
      >
        <v-radio
          v-for="option in options1"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) What will happen to the temperature of the water?</p>

      <v-radio-group
        v-model="inputs.waterChangeDirection"
        :disabled="!allowEditing"
        class="ml-6 mb-0"
      >
        <v-radio
          v-for="option in options2"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">d) Calculate the final temperature inside the styrofoam container.</p>

      <calculation-input
        v-model="inputs.finalTemp"
        class="mb-0"
        prepend-text="$\text{T:}$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question458',
  components: {
    LatexNumber,
    NumberValue,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molWater: null,
        significanceStyrofoam: null,
        waterChangeDirection: null,
        finalTemp: null,
      },
      options1: [
        {expression: 'It is inert', value: 'inert'},
        {expression: "It wouldn't absorb much heat from the reaction", value: 'insulator'},
        {expression: 'It is inexpensive', value: 'cheap'},
        {
          expression: 'It does not matter. An aluminum can would work just as well.',
          value: 'doesNotMatter',
        },
      ],
      options2: [
        {expression: '$\\text{Increase}$', value: 'increase'},
        {expression: '$\\text{Decrease}$', value: 'decrease'},
        {expression: '$\\text{More information required}$', value: 'moreInfoNeeded'},
      ],
    };
  },
  computed: {
    molMetal() {
      return this.taskState.getValueBySymbol('molMetal').content;
    },
    tempMetal() {
      return this.taskState.getValueBySymbol('tempMetal').content;
    },
    volWater() {
      return this.taskState.getValueBySymbol('volWater').content;
    },
    Cmetal() {
      return this.taskState.getValueBySymbol('Cmetal').content;
    },
  },
};
</script>
